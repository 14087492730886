import React from 'react';
import { css } from 'styled-components';
import { mediaQueries, breakpoints } from '../../../utils/tokens';
export const Container = ({ children, ...rest }) => {
  return (<div css={css({
    [mediaQueries.lg]: {
      maxWidth: breakpoints.xxxl,
      margin: `0 auto`,
    },
  })} {...rest}>
    {children}
  </div>);
};

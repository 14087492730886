import React from 'react'
import { Helmet } from 'react-helmet'

const MetaNoIndex = () => {
  return (
    <Helmet>
      <meta name="robots" content="noindex" />
    </Helmet>
  )
}

export default MetaNoIndex
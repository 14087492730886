import React from 'react'
import { css } from 'styled-components'
import { Link } from 'gatsby'

import Layout from '../../components/Layout'

import Astronaut from '../../assets/icons/astronaut.svg'
import { Container } from '../../views/instant-quote/components/Container'
import { breakpoints, space, fontSizes, colors, palette, boxShadows } from '../../utils/tokens'

import MetaNoIndex from '../../components/MetaNoIndex'


const Success3DPrinting = () => {
  return (
    <Layout>
      <main
        css={css({
          backgroundColor: palette.grey[10],
        })}
      >
        <MetaNoIndex />
        <Container>
          <div
            css={css({
              maxWidth: breakpoints.md,
              margin: `0 auto`,
              padding: `${space[6]}px ${space[4]}px `,
              display: `flex`,
              justifyContent: `center`,
            })}
          >
            <div
              css={css({
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `center`,
                alignItems: `center`,
                padding: `${space[4]}px ${space[4]}px ${space[5]}px`,
                backgroundColor: colors.white,
                borderRadius: `${space[2]}px`,
                border: `1px solid ${palette.grey[40]}`,
                boxShadow: boxShadows.card,
              })}
            >
              <h1
                css={css({
                  fontWeight: `bold`,
                  textAlign: `center`,
                  color: colors.success,
                  marginBottom: `${space[1]}px`,
                })}
              >
                Success!
              </h1>
              <div
                css={css({
                  display: `flex`,
                  flexDirection: `column`,
                })}
              >
                <h3
                  css={css({
                    textAlign: `center`,
                    fontWeight: `bold`,
                    marginBottom: `${space[2]}px`,
                  })}
                >
                  We've received your inquiry!
                </h3>
                <p
                  css={css({
                    marginBottom: `${space[3]}px`,
                    textAlign: `center`,
                  })}
                >
                  You'll get an email from us asap.
                </p>
                <Astronaut
                  css={css({
                    maxWidth: `150px`,
                    height: `auto`,
                    marginBottom: `${space[3]}px`,
                    alignSelf: `center`,
                  })}
                />
                <section
                  css={css({
                    marginBottom: `${space[3]}px`,
                  })}
                >
                  <h5
                    css={css({
                      fontSize: fontSizes[3],
                      color: palette.grey[80],
                    })}
                  >
                    In the meantime...
                  </h5>
                  <p>
                    You can check out some of our 3D printing work{' '}
                    <Link to="/gallery/3d-printing/">here</Link>.
                  </p>
                </section>
                <section>
                  <h5
                    css={css({
                      fontSize: fontSizes[3],
                      color: palette.grey[80],
                    })}
                  >
                    ...or follow us
                  </h5>
                  <p>
                    on Instagram at{' '}
                    <a
                      href="https://www.instagram.com/printaworld3d"
                      target="_blank"
                      rel="noopener noreferrer"
                      css={css({
                        textDecorationColor: colors.primaryBrand,
                      })}
                    >
                      @printaworld3d
                    </a>
                  </p>
                </section>
              </div>
            </div>
          </div>
        </Container>
      </main>
    </Layout>
  )
}

export default Success3DPrinting